.nav-links-container {
    display: flex;
    justify-content: center;
  }
.content {
    position: sticky;
    top: 0;
    
  }
  .show-sidebar {
    margin-left: 0;
  }
  header {
    height: 6rem;
    display: flex;
    align-items: center;
    padding-left: 2.5rem;
  }
  .nav-links {
    max-width:1120px;
    padding-top: 0.5rem;
    display: flex;
    flex-direction: row;
      justify-content: space-between;  
      width:90%


  }
  .nav-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--grey-500);
    /* padding: 1rem 0; */
    /* padding-left: 2.5rem; */
    text-transform: capitalize;
    transition: var(--transition);
  }
  .nav-link:hover {
    /* background: var(--grey-50); */
    /* padding-left: 3rem; */
    color: var(--grey-900);
  }
  .nav-link:hover .icon {
    color: #6366f1 ;
  }
  .icon {
    font-size: 1.5rem;
    /* margin-right: 1rem; */
     display: grid; 
    place-items: center; 
    transition: var(--transition);
  }
  .active {
    color: var(--grey-900);
  }
  .active .icon {
    color:#6366f1 ;
  }

  @media (max-width: 768px) {
    .nav-text {
      display: none;
    }
  }

.active  .nav-text  {
    color:#6366f1 
  }