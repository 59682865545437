.image-col {
  position: relative;
  overflow: hidden;
}

.image-col::before {
  content: "";
  display: block;
  padding-top: 10%; /* this value can be adjusted to change the skew angle */
  transform: skewY(-6deg);
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.image-col img {
  position: relative;
  z-index: 1;
}

.plain-link-center {
  text-decoration: none;
  color: #6366f1;
  /* padding-left: 15px; */
}

.plain-link {
  text-decoration: none;
  color: #6366f1;
  /* padding-left: 15px; */
}

.circle-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: relative;
  color: #6366f1;
}

.circle-icon::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 50%;
  z-index: -3;
}

.circle-icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ede9fe;
  border-radius: 50%;
  z-index: -2;
}

.list-group-item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 10%; /* Adjust this to position the vertical line correctly */
  left: 35px; /* adjust as needed */
  height: calc(110%); /* Adjust this to control the vertical line length */
  width: 1px;
  background-color: lightgray;
  z-index: 99;
}

.list-group-item {
  border: none !important;
  position: relative;
}

.custom-btn {
  background-color: #6366f1 !important; /* change the button color */
  border-color: #6366f1 !important; /* change the border color */
}

.custom-btn:hover {
  background-color: #5a5fd9 !important; /* change the hover color */
  border-color: #5a5fd9 !important; /* change the hover border color */
}

.custom-btn-large {
  background-color: #6366f1 !important; /* change the button color */
  border-color: #6366f1 !important; /* change the border color */
}

.custom-btn-large:hover {
  background-color: #5a5fd9 !important; /* change the hover color */
  border-color: #5a5fd9 !important; /* change the hover border color */
}

.title {
  color: #6366f1;
  font-weight: bold;
}

.position-relative {
  max-width: 100%;
  height: 300px;
  object-fit: cover;
  overflow: hidden;
}

.image-padding {
  padding-top: 0px !important;
}

.img-clip-path {
  -webkit-clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
}

.img-clip-path-left {
  -webkit-clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
}

.text-large {
  font-size: 18px;
}

.my-card {
  max-width: 500px; /* Set the maximum width you want for your cards */
  margin: auto; /* Centers the card */
}

.image-container {
  height: 100%;
  max-height: 600px;
  overflow: hidden;
}

.image-container img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.max-width-img {
  max-width: 600px; /* or whatever maximum width you want */
  align-self: center;
}
