.table-btn {
  background-color: var(--bs-primary) !important;
}
.checked-class {
  color: #6366f1;
  background-color: #6366f1 !important;
  border-color: #5a5fd9 !important;
}
.unchecked-class {
  color: #6b6b79 !important;
  border-color: #5a5fd9 !important;
  text-decoration: line-through !important;
}
