.form-center {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-between;
}

.checkbox-row {
  display: flex;
  align-items: center;
}

.info-icon {
  margin-left: 10px;
}
